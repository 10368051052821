<template>
  <footer>
    <ul class="footer-nav">
        <li class="footer-li" @click="showForm">
            <a href="javascript:;">
                <!-- <img src="https://www.bzwz.com/static/m/images/icon51.png" alt=""> -->
                <img src="@/assets/img/common/icon51.png" alt="">
                评论
            </a>
        </li>
      <li class="footer-li">
        <a
          href="https://tb.53kf.com/code/client/a7c96e4ceb10e87d6fc792aec24104e05/3"
        >
          <!-- <img src="https://www.bzwz.com/static/m/images/icon52.png" alt /> -->
          <img src="@/assets/img/common/icon52.png" alt="" />
          客服
        </a>
      </li>
      
      <li id="add" :class="{'footer-li button': true, 'buttonHide': !isBack, 'buttonPosition': isBack}">
        <a href="javascript:;" @click="handleBack">返回</a>
      </li>
      <li class="footer-li button" style="background-color: #df0024; margin-right: 0;" v-if="isPlay">
          <slot>
            <a href="javascript:;" class="play-button" @click="handlePlay">
                {{ playText }}
            </a>
          </slot>
      </li>
    </ul>
    <div class="form" v-show="isWriting">
      <div class="operation">
        <div class="cancel" @click="hideForm">取消</div>
        <div class="confirm" @click="submit">发表</div>
      </div>
      <textarea
        id="comment"
        @input="handleInput"
        name="comment"
        placeholder="我也来说两句..."
      ></textarea>
    </div>
  </footer>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "classFooter",
  props: {
    isBack: {
        type: Boolean,
        default: true
    },
    modelValue: {
      type: String,
      default: "",
    },
    playText: {
      type: String,
      default: "播放"
    },
    isPlay: {
      type: Boolean,
      default: true
    }
  },
  setup(props, ctx) {
    
    const router = useRouter();
    function handleBack(){
        router.back();
    }

    function handlePlay(){
      ctx.emit("handle-play");
    }

    const isWriting = ref(false);
    const content = ref("");
    function showForm() {
      isWriting.value = true;
    }
    function hideForm() {
      isWriting.value = false;
    }
    function submit() {
      ctx.emit("submit");
      hideForm();
    }
    function handleInput(e: Event) {
      ctx.emit("update:modelValue", (e.target as HTMLTextAreaElement).value);
    }

    return {
      handleBack,
      handlePlay,
      isWriting,
      submit,
      showForm,
      hideForm,
      handleInput
    };
  }
});
</script>

<style lang="scss" scoped>
footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50px;
  border-top: 1.012px solid #f4f4f4;
  font-size: 14px;
  color: #666;
  background-color: #fff;
  z-index: 100;
}

footer a {
  font-size: 14px;
  color: #666;
  display: block;
}

.footer-nav {
  position: relative;
}

.footer-li {
  float: left;
  width: 17%;
  text-align: center;
  position: relative;
}

.footer-li img {
  display: block;
  width: 20;
  height: 20px;
  margin: 5px auto;
}

.footer-li .active-status {
  position: absolute;
  display: none;
  left: 50%;
  margin-left: -10px;
  top: 0;
  z-index: 10;
  background-color: #fff;
}

.footer-nav .active a {
  color: #df0024;
}

.footer-nav .active .active-status {
  display: block;
}

footer .button {
  width: 110px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  background-color: #e7c527;
  color: #fff;
  border-radius: 36px;
  margin-top: 8px;
  margin-right: 11px;
}

footer .button a {
  color: #fff;
}

.buttonHide{
  visibility: hidden;
}
.buttonPosition {
  float: right;
}
.form-field {
  width: 365px;
  height: 40px;
  margin: 8px auto;
  position: relative;
  padding-right: 97px;
  box-sizing: border-box;

  line-height: 40px;
}
.form-field span {
  padding-left: 15px;
  box-sizing: border-box;
  font-size: 16px;
  background-color: #f4f4f4;
  color: #444444;
  line-height: 40px;
  display: block;
}
.form-field input {
  width: 100%;
  height: 40px;
  line-height: 40px;
  outline: none;
  border: none;
  background-color: transparent;
  vertical-align: middle;
  padding-left: 15px;
  box-sizing: border-box;
  font-size: 16px;
  background-color: #f4f4f4;
}

.form {
  border-top: 1px solid #eee;
  position: absolute;
  width: 100%;
  left: 0;
  height: 133px;
  bottom: 0;
  background-color: #fff;
  padding: 7.5px 10px;
  box-sizing: border-box;
  z-index: 100000;
}
.operation {
  height: 35px;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.operation div {
  float: left;
  width: 50%;
  font-size: 14px;
  color: #444444;
  box-sizing: border-box;
}

.operation .confirm {
  float: right;
  color: #df0024;
  text-align: right;
}

textarea {
  width: 100%;
  box-sizing: border-box;
  padding: 7.5px;
  color: #444444;
  font-size: 14px;
  height: 75px;
  background: #f4f4f4;
  border-radius: 5px;
  margin-top: 5px;
  border: none;
  outline: none;
}

</style>
