<template>
  <div class="comment">
    <ul>
      <li v-for="item in listComment" :key="item.UId">
         <router-link class="comment-avatar" :to="{ name: 'Personal', query: { uid: item.uid } }">
            <img :src="item.user.userImg" alt="" />
        </router-link>
        <div class="comment-info">
          <div class="nick-name">
            {{ item.user.nickName }}
          </div>
         <router-link :to="{name: 'CourseCommentDetail', query: { id: item.id} }">
          <div class="comment-content" v-html="item.content"></div>
          <div class="comment-date">
            <span>{{ item.createTime && item.createTime.substr(0, 16) }}</span>
            <!-- <span>{{ timeFormat(item.createTime) && timeFormat(item.createTime).substr(0, 16) }}</span> -->
            <!-- {{ dateFormat(item.addTime, "HH:mm") }} -->
            <span class="reward"> {{item.commontCount}} 回复</span>

          </div>
         </router-link>
        </div>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

//  utils

import { timeFormat } from "@/utils/dateFormat";

export default defineComponent({
  name: "Recommend",
  props: {
    listComment: Array,
  },
  setup(props, ctx) {
    function onStar() {
      ctx.emit("star");
    }
    return {
      timeFormat,
      onStar,
    };
  },
});
</script>

<style lang="scss" scoped>
.comment {
  li {
    position: relative;
    padding: 17.5px 10px;
    box-sizing: border-box;
    border-bottom: 1px solid #eee;
    overflow: hidden;
    padding-left: 55px;
    .comment-avatar {
      position: absolute;
      left: 10px;
      top: 17.5px;
      img {
        display: block;
        width: 35px;
        height: 35px;
        border-radius: 50%;
      }
    }
  }
}

.nick-name {
  color: #666666;
  font-size: 16px;
  position: relative;
  padding-right: 75px;
}
.comment-info {
  text-align: left;
}
.comment-content {
  margin-top: 10px;
  font-size: 15px;
  line-height: 22px;
  color: #444444;
}
.comment-date {
  margin-top: 10px;
  color: #999999;
  font-size: 14px;
}

.comment-date span {
  display: inline-block;
  margin-right: 10px;
}
.reward {
  padding: 0 7.5px;
  background: #eee;
  height: 22.5px;
  line-height: 22.5px;
  border-radius: 10px;
  margin-left: 10px;
}
.comment-star {
  position: absolute;
  right: 10px;
  top: 17.512px;
  color: #666;
  font-size: 14px;
  img {
    width: 15px;
    height: 15px;
    vertical-align: middle;
    margin-right: 5px;
    position: relative;
    top: -2px;
  }
}

.adjacent-news {
  margin: 10px auto;
  text-align: left;
  width: 355px;
  h3 {
    margin: 0;
    font-weight: normal;
    margin: 0 10px;
  }
  a {
    display: block;
    margin-top: 10px;
    font-size: 14px;
    font-weight: normal;
    color: #666;
    span {
      color: #05b3d2;
      font-size: 14px;
    }
  }
}
.reward {
    display: inline-block;
    padding: 0 0.2rem;
    background: #eee;
    height: 0.6rem;
    line-height: 0.6rem;
    border-radius: 0.267rem;
    margin-left: 0.267rem;
}
</style>
